<template>
  <el-container>
    <el-header class="header" height="2.70833vw">
      <!-- 左侧 -->
      <div class="header-left">
        <div class="header-title">
          <div class="header-title-icon" />
          我的工作空间
        </div>
        <el-button @click="handleNameAdd" class="header-button">
          <img src="~a/workbench/add.png" alt="" /> 新建工作区
        </el-button>
        <el-button @click="handleDel" class="header-button">
          <img src="~a/workbench/del.png" alt="" /> 删除选中
        </el-button>
      </div>
    </el-header>
    <el-container class="main-container">
      <el-main class="main">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          :max-height="tableMaxHeight"
          @selection-change="handleSelectionChange"
          @row-contextmenu="handleRowContextmenu"
          @row-click="handleRowClick"
          :row-class-name="tableRowClassName"
          v-loading="loading"
          style="widht: 100%"
          element-loading-text="拼命加载中"
        >
          <el-table-column
            type="selection"
            :selectable="checkboxSelect"
            width="74"
          />
          <el-table-column
            :label="
              ArrSelection.length >= 1
                ? '已选中' + ArrSelection.length + '个工作空间'
                : '工作空间'
            "
            prop="name"
            min-width="45%"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <!-- 文件名称 -->
              <EditText
                :id="scope.row.id"
                :value="scope.row.name"
                :maxlength="35"
                @edit="text => handleNameEdit(text, scope.row)"
                @del="getTableData"
              >
                <template #text>
                  <div class="folderName">
                    <p>{{ scope.row.name }}</p>
                  </div>
                </template>
              </EditText>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" prop="createDate" min-width="42%" />
          <!-- <el-table-column label="操作">
            <template slot-scope="scope">
              <span v-if="scope.row.defaultSelect" class="main-status"
                >默认选择中</span
              >
              <span
                v-else
                class="main-status"
                @click.stop="handleSetDefault(scope.row)"
                >设为默认</span
              >
            </template>
          </el-table-column> -->
        </el-table>
        <!-- 分页组件 -->
        <Pagination
          @handleCurrentChange="handleCurrentChange"
          :page="page"
          :total="total"
          :pageSize="15"
        />
      </el-main>
    </el-container>
    <!-- 右键菜单 -->
    <div
      class="contextMenu"
      v-if="contextmuenPosition && ArrSelection.length !== 0"
      :style="{ ...contextmuenPosition }"
    >
      <div @click="reaname" v-show="ArrSelection.length == 1">重命名</div>
      <div @click="handleDel">删除</div>
    </div>
  </el-container>
</template>

<script>
import EditText from 'c/basic/EditText';
import { time } from 'p/Times.js';
import Pagination from 'c/basic/Pagination.vue';
export default {
  components: { EditText, Pagination },
  name: 'Workspace',
  data() {
    return {
      tableData: [], // 表格数据
      name: '', //新建工作空间名称
      multipleSelection: [], // 选中数据
      ArrSelection: [], // 选中ID
      contextmuenPosition: '', //右击菜单位置
      contextmenuRow: {}, // 右击行数据
      currentTime: '', // 获取当前时间
      page: 1, // 页码
      total: 0, //数据数
      loading: false, //加载页
      tableMaxHeight: 662 // 表格最大高度
    };
  },
  computed: {
    editTextId: {
      get() {
        return this.$store.state.common.editTextId;
      }
    }
  },
  created() {
    // 点击右击菜单外关闭
    document.addEventListener('click', () => {
      this.contextmuenPosition = '';
    });
    this.getTableData();
    // 表格最大高度
    this.$nextTick(() => {});
  },
  mounted() {
    const vm = this;
    vm.tableMaxHeight = window.innerHeight - 298;
    window.onresize = function () {
      vm.tableMaxHeight = window.innerHeight - 298;
    };
  },
  methods: {
    // 分页切换
    handleCurrentChange(val) {
      this.page = val;
      this.getTableData();
    },
    // 获取表格数据
    getTableData() {
      this.loading = true;
      const params = {
        page: this.page
      };
      this.$service.workspace
        .workspaceGetPage(params)
        .then(data => {
          if (data.data.status === 200) {
            if (
              data.data.totalPage < data.data.page &&
              data.data.totalPage != 0
            ) {
              this.page = 1;
              return this.getTableData();
            }
            this.tableData = data.data.data;
            this.page = data.data.page;
            this.total = data.data.total;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
      // 关闭右击菜单
      this.contextmuenPosition = '';
      this.$store.commit('common/setEditTextId', '');
    },
    // 临时新建数据
    handleNameAdd() {
      if (this.editTextId !== '') return;
      this.getCurrentTime();
      var newChild = {
        id: Math.floor(Math.random() * 10000 + 100000) + '',
        name: '',
        createDate: this.currentTime
      };
      this.tableData.push(newChild);
      this.$store.commit('common/setEditTextId', newChild.id);
    },
    // 编辑
    handleNameEdit(text) {
      if (!text) {
        this.$message.error('工作空间名称不能为空，请输入工作空间名称');
      } else {
        this.loading = true;
        if (this.editTextId.length > 10) {
          const params = {
            id: this.editTextId,
            name: text
          };
          this.$service.workspace
            .workspaceModifyName(params)
            .then(data => {
              if (data.data.status === 200) {
                this.$store.commit('common/setEditTextId', '');
                this.$message.success(data.data.msg);
                this.getTableData();
              }
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          // 创建文件夹
          const params = {
            name: text
          };
          this.$service.workspace
            .workspaceCreate(params)
            .then(data => {
              if (data.data.status === 200) {
                this.$store.commit('common/setEditTextId', '');
                this.$message.success(data.data.msg);
                this.getTableData();
              }
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        }
      }
    },
    // 重命名
    reaname() {
      this.$store.commit('common/setEditTextId', this.ArrSelection[0]);
    },
    // 默认空间禁止选中删除
    checkboxSelect(row) {
      if (row.name === '系统默认') {
        return false; // 禁用
      } else {
        return true; // 不禁用
      }
    },
    //多选数据
    handleSelectionChange(val) {
      this.multipleSelection = val;
      //储存批量删除名称的数组
      var arr = [];
      val.forEach(item => {
        arr.push(item.id);
      });
      this.ArrSelection = arr;
    },
    // 批量删除
    handleDel() {
      if (this.ArrSelection == '') {
        this.$message('请选择您要删除的内容');
      } else {
        this.$confirm('删除后不能恢复，确定要删除吗？', '删除确认', {
          confirmButtonText: '删除',
          cancelButtonText: '取消',
          type: 'warning',
          customClass: 'del'
        })
          .then(() => {
            this.loading = true;
            let params = new FormData();
            for (let i = 0; i < this.ArrSelection.length; i++) {
              const element = this.ArrSelection[i];
              params.append('id', element);
            }
            this.$service.workspace
              .workspaceDel(params)
              .then(data => {
                if (data.data.status === 200) {
                  this.$message.success(data.data.msg);
                  this.getTableData();
                }
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          })
          .catch(() => {});
      }
    },
    // table的右键点击当前行事件
    handleRowContextmenu(row, column, event) {
      // 系统默认空间禁止右击操作
      if (row.name === '系统默认') return;
      this.contextmenuRow = row;
      this.contextmuenPosition = {
        left: event.clientX + 'px',
        top: event.clientY + 'px'
      };
      // 允许多个选中状态下的右击选中行
      if (this.ArrSelection.includes(row.id)) return;
      this.$refs.multipleTable.clearSelection();
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    // 点击行事件
    handleRowClick(row) {
      if (row.name === '系统默认' || this.editTextId === row.id) return;
      if (this.$refs.multipleTable.selection.length == 1) {
        var rowClickId = this.$refs.multipleTable.selection[0].id;
      }
      this.$refs.multipleTable.clearSelection();
      this.$refs.multipleTable.toggleRowSelection(row);
      let rowClickId1 = this.$refs.multipleTable.selection[0].id;
      if (rowClickId == rowClickId1) {
        this.$refs.multipleTable.clearSelection();
      }
    },
    // 选中行颜色
    tableRowClassName(row) {
      if (this.ArrSelection.includes(row.row.id)) {
        return 'warm-row';
      }
    },
    // 获取当前时间
    getCurrentTime() {
      var timestamp = Date.parse(new Date());
      this.currentTime = time.formatTime(
        Number(timestamp),
        'yyyy-MM-dd&hh:mm:ss'
      );
    }
  }
};
</script>

<style lang="less" scoped>
// 头部按钮
.header-button {
  margin: 0 27px 20px -7px;
  width: 180px;
  height: 42px;
  background: #eff3fb;
  border: none;
  border-radius: 12px;
  font-size: 18px;
  color: #507cff;
  img {
    margin-right: 5px;
    height: 18px;
    width: 18px;
  }
}
.main {
  margin-top: 20px;
}
// 右键菜单
.contextMenu {
  position: absolute;
  left: 600px;
  width: 106px;
  background: #ffffff;
  box-shadow: 0px 6px 8px 0px rgba(71, 105, 163, 0.15);
  border-radius: 8px;
  overflow: hidden;
  z-index: 1000;
  div {
    width: 106px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    font-weight: 400;
    font-family: Microsoft YaHei;
    color: #333333;
    &:hover {
      background-color: #507cff;
      color: #ffffff;
    }
  }
}

// 新建工作区弹窗
/deep/.addDialog {
  margin-top: 15vh;
  .el-dialog {
    height: 240px;
    width: 546px;
    .el-dialog__header {
      border-bottom: none;
      margin-top: 8px;
    }
  }
  .el-input {
    margin-top: 10px;
  }
  .btn {
    margin-top: 30px;
    float: right;
    font-size: 18px;
    color: #ffffff;
    width: 120px;
    height: 40px;
    border-radius: 6px;
    border: none;
  }
  .btn1 {
    margin-right: 20px;
    background: #d6e0ff;
  }
  .btn2 {
    background: #507cff;
  }
}
// 表格行背景色
/deep/.warm-row {
  background-color: #f5f8ff !important;
}
</style>
