<!-- 分页器 -->
<template>
  <div class="pagination">
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="page"
      :hide-on-single-page="total === 0"
      layout="total, prev, pager, next, jumper"
      :total="total"
      :page-size="pageSize"
      style="text-align: center"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    page: {
      default: ''
    },
    total: {
      default: ''
    },
    pageSize: {
      default: ''
    }
  },
  data() {
    return {};
  },
  methods: {
    handleCurrentChange(val) {
      this.$emit('handleCurrentChange', val);
    }
  }
};
</script>

<style lang="less">
.pagination {
  position: absolute;
  bottom: 35px;
  width: 1560px;
}
</style>
