export const time = {
  formatTime(curTime, type) {
    if (!curTime) {
      return '';
    }
    var nd = new Date(curTime);
    var y = nd.getFullYear();
    var mm = nd.getMonth() + 1;
    var d = nd.getDate();
    var h = nd.getHours();
    var m = nd.getMinutes();
    var s = nd.getSeconds();
    if (mm < 10) {
      mm = '0' + mm;
    }
    if (d < 10) {
      d = '0' + d;
    }
    if (h < 10) {
      h = '0' + h;
    }
    if (m < 10) {
      m = '0' + m;
    }
    if (s < 10) {
      s = '0' + s;
    }
    if (type == 'MM-DD') {
      return mm + '-' + d;
    } else if (type == 'yyyy-MM-dd') {
      return y + '-' + mm + '-' + d;
    } else if (type == 'week') {
      return y + '年' + mm + '月' + d + '日　';
    } else if (type == 'MM-DD&week') {
      return mm + '-' + d + '　';
    } else if (type == 'MM-DD&hh:mm') {
      return mm + '-' + d + ' ' + h + ':' + m;
    } else if (type == '年月日') {
      return y + '年' + mm + '月' + d + '日　';
    } else if (type == 'yyyy.MM.dd&hh:mm') {
      return y + '.' + mm + '.' + d + ' ' + h + ':' + m;
    } else if (type == 'yyyy.MM.dd&hh:mm:ss') {
      return y + '.' + mm + '.' + d + ' ' + h + ':' + m + ':' + s;
    }
    return y + '-' + mm + '-' + d + ' ' + h + ':' + m;
  }
};
