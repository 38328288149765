<template>
  <div class="editText">
    <div class="editText-ipt" v-if="editTextId == id">
      <slot>
        <input
          class="editText-ipt__inner"
          :style="{ borderColor: [txet.length > maxlength ? 'red' : ''] }"
          type="text"
          ref="ipt"
          v-focus
          autofocus="autofocus"
          :maxlength="maxlength"
          v-model.trim="txet"
          onfocus="this.select()"
          @keyup.enter="confirm"
        />
        <!-- @change="confirm"
          @blur="confirm" -->
        <span class="editText-ipt-showWordLimit" v-show="maxlength"
          >{{ txet.length }}/{{ maxlength }}</span
        >
        <span class="editText-ipt-datasetCopy" v-show="txet.length > maxlength"
          >字符超出限制，请重新命名</span
        >
        <button class="btn confirm" @click.stop="confirm">
          <i class="iconfont icon-gouxuan"></i>
        </button>
        <button class="btn cancel" @click.stop="cancel">
          <i class="iconfont icon-close"></i>
        </button>
      </slot>
    </div>
    <div v-else class="editText-text">
      <slot name="text">
        {{ value }}
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditText',
  props: {
    value: String,
    id: String,
    maxlength: Number
  },
  data() {
    return {
      txet: this.value,
      originValue: this.value
    };
  },
  computed: {
    editTextId: {
      get() {
        return this.$store.state.common.editTextId;
      },
      set(val) {
        this.$store.commit('common/setEditTextId', val);
      }
    }
  },
  methods: {
    confirm() {
      this.$emit('edit', this.$refs.ipt ? this.$refs.ipt.value : this.value);
    },
    cancel() {
      this.$emit('del', this.originValue);
      this.editTextId = '';
      this.txet = this.value;
    }
  },
  watch: {
    value: {
      handler(val) {
        this.txet = val;
      }
    }
  }
};
</script>

<style lang="less" scoped>
// 编辑文本
.editText {
  height: 38px;
  &-ipt {
    display: flex;
    align-items: center;
    height: 100%;
    // 输入框
    &__inner {
      flex: 1;
      box-sizing: border-box;
      width: 0;
      height: 100%;
      padding-left: 10px;
      padding-right: 55px;
      border-radius: 6px;
      border: 1px solid #c9c9c9;
      font-size: inherit;
      &:focus {
        border-color: #507cff;
      }
    }
    // 展示字数统计
    &-showWordLimit {
      position: absolute;
      right: 115px;
      font-size: 14px;
      line-height: initial;
      color: #666;
    }
    // 字符超出限制
    &-datasetCopy {
      position: absolute;
      font-size: 12px;
      margin-top: 50px;
      color: red;
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 26px;
      height: 26px;
      margin-left: 20px;
      border-radius: 4px;
      .iconfont {
        font-size: 18px;
      }
      &.confirm {
        background: #507cff;
        color: #fff;
      }
      &.cancel {
        background: #d6e0ff;
        color: #507cff;
      }
    }
  }
  &-text {
    line-height: 38px;
  }
}
</style>
